import EditorData from 'components/editor-data/EditorData';
import CustomerHelperLoader from 'components/data/CustomerHelperLoader';
import Setup from 'components/data/Setup';
import PublishHelpers from 'components/data/PublishHelpers';
import TemplateHelpers from 'components/data/TemplateHelpers';

/**
 * CreativeBuilderPublishGoogleAds
 * Publish to Google Ads
 */
export default class CreativeBuilderPublishGoogleAds {
    static structureDone = {};

    /**
     * handleItem
     * Publish an item to the Google Ads channel
     * @param {*} uuid
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} feedData
     * @param {*} isFinalSet
     */
    static async handleItem(uuid, setNr, task, data, language, subsetItem, subsetItemNr, prefix, feedData, isFinalSet) {
        const channelSetup = data.channelSetup;
        const channelData = data.data[subsetItem].channelData;
        const assetData = data.data[subsetItem].assetData;
        return await this.handleGoogleAds(
            uuid,
            setNr,
            task,
            data,
            language,
            subsetItem,
            subsetItemNr,
            channelSetup,
            channelData,
            assetData,
            feedData,
            isFinalSet
        );
    }

    /**
     * Handle Google Ads publish
     * @param {*} uuid
     * @param {*} setNr
     * @param {*} task
     * @param {*} data
     * @param {*} language
     * @param {*} subsetItem
     * @param {*} subsetItemNr
     * @param {*} channelSetup
     * @param {*} channelData
     * @param {*} assetData
     * @param {*} feedData
     * @param {*} isFinalSet
     */
    static async handleGoogleAds(uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, assetData, feedData, isFinalSet) {
        const newTasks = [];

        const customerHelper = new CustomerHelperLoader.helper();

        console.log('@@data', data);

        let structure = CreativeBuilderPublishGoogleAds.generateDefaultStructure({
            uuid,
            setNr,
            task,
            data,
            language,
            subsetItem,
            subsetItemNr,
            channelSetup,
            channelData,
            assetData
        });

        // Predefined structure for this customer
        if (customerHelper.publishGoogleAdsStructure) {
            structure = customerHelper.publishGoogleAdsStructure(
                uuid,
                setNr,
                task,
                data,
                language,
                subsetItem,
                subsetItemNr,
                channelSetup,
                channelData,
                assetData,
                feedData,
                isFinalSet,
                structure
            );
        }
        // Create structure
        structure.forEach((item) => {
            // Validate whether we didn't already published it in this job
            if (item.pointer && !CreativeBuilderPublishGoogleAds.structureDone[item.pointer]) {
                CreativeBuilderPublishGoogleAds.structureDone[item.pointer] = true;
                newTasks.push({
                    ...item,
                    service: !item.service ? 'googleads' : item.service,
                    campaignIdentifier: item.campaignIdentifier ? item.campaignIdentifier : EditorData.getId()
                });
            }
        });

        return newTasks;
    }

    /**
     * Generate default structure
     */
    static generateDefaultStructure({ uuid, setNr, task, data, language, subsetItem, subsetItemNr, channelSetup, channelData, assetData, prefix }) {
        const tasks = [];

        if (channelSetup?.adType && channelSetup.adType === 'demandGen') {
            // Get base settings
            const campaignPointer = 'campaigndesigner_' + EditorData.getId() + '-googleads';
            const adGroupPointer = campaignPointer + '-' + language + '-campaign_' + uuid;
            const adPointer = adGroupPointer + '-subset_' + subsetItem;
            const customerId = Setup.getValueFromModel('publish.googleAds.customerId');
            const uniqueName = PublishHelpers.creativeBuilderNaming({ type: 'social', uuid, subsetItem, language, prefix });

            // Content
            const businessName = EditorData.getValueFromModel('businessName.' + language + '.value', channelData);
            const link = EditorData.getValueFromModel('link.' + language + '.value', channelData);
            const headlines = [];
            const descriptions = [];

            for (let i = 1; i <= 5; i++) {
                const headlineContent = EditorData.getValueFromModel('headline' + (i > 1 ? i : '') + '.' + language + '.value', channelData);
                const descriptionContent = EditorData.getValueFromModel('description' + (i > 1 ? i : '') + '.' + language + '.value', channelData);

                if (headlineContent) {
                    headlines.push({ text: headlineContent });
                }
                if (descriptionContent) {
                    descriptions.push({ text: descriptionContent });
                }
            }

            // Adgroup
            const task_adGroup = {
                service: 'googleads',
                type: 'adGroup',
                pointer: adGroupPointer,
                campaignIdentifier: EditorData.getId(),
                managerId: 6225366254,
                customerId: customerId,
                skipIfExists: true,
                settings: {
                    campaign: 'customers/1421159531/campaigns/22157517019',
                    name: data.title,
                    status: 'PAUSED'
                }
            };
            tasks.push(task_adGroup);

            // Add logo
            const logoUrl = EditorData.getValueFromModel('logo.url', channelData);
            const task_logo = {
                service: 'googleads',
                type: 'asset',
                pointer: adPointer + '-logo',
                campaignIdentifier: EditorData.getId(),
                managerId: 6225366254,
                customerId: customerId,
                skipIfExists: true,
                settings: {
                    assetType: 'image',
                    name: data.title + ' logo',
                    url: logoUrl
                }
            };
            tasks.push(task_logo);

            // Sort formats
            let formats = data.assetSetup?.settings?.formats;
            if (!formats) {
                formats = [];
            }

            const square_marketing_images = [];
            const marketing_images = [];
            const portrait_marketing_images = [];

            // Loop through asset formats
            for (const formatData of formats) {
                const formatKey = formatData.key ? formatData.key : formatData.format;
                const dimension = TemplateHelpers.getFormatDimension(formatData);

                // Add asset by dimension
                if (dimension === '1x1' || dimension === '4x5' || dimension === '1.91x1') {
                    const task_image = {
                        service: 'googleads',
                        type: 'asset',
                        pointer: adPointer + '-image-' + formatKey,
                        campaignIdentifier: EditorData.getId(),
                        managerId: 6225366254,
                        skipIfExists: true,
                        customerId: customerId,
                        settings: {
                            assetType: 'image',
                            name: data.title + ' image ' + formatKey,
                            url: '{{' + uniqueName + '-' + formatKey + '_asset}}'
                        }
                    };
                    tasks.push(task_image);

                    if (dimension === '1x1') {
                        square_marketing_images.push({
                            asset_pointer: adPointer + '-image-' + formatKey
                        });
                    }
                    if (dimension === '1.91x1') {
                        marketing_images.push({
                            asset_pointer: adPointer + '-image-' + formatKey
                        });
                    }
                    if (dimension === '4x5') {
                        portrait_marketing_images.push({
                            asset_pointer: adPointer + '-image-' + formatKey
                        });
                    }
                }
            }

            const task = {
                service: 'googleads',
                type: 'adGroupAd',
                campaignIdentifier: EditorData.getId(),
                skipIfExists: true,
                pointer: adPointer,
                managerId: 6225366254,
                customerId: customerId,
                settings: {
                    ad_group_pointer: adGroupPointer,
                    status: 'PAUSED',
                    ad: {
                        name: data.title,
                        final_urls: [link],
                        demand_gen_multi_asset_ad: {
                            square_marketing_images: square_marketing_images,
                            marketing_images: marketing_images,
                            portrait_marketing_images: portrait_marketing_images,
                            logo_images: [
                                {
                                    asset_pointer: adPointer + '-logo'
                                }
                            ],
                            business_name: businessName,
                            headlines: headlines,
                            descriptions: descriptions
                        }
                    }
                }
            };
            tasks.push(task);
        }

        return tasks;
    }
}
